<template>
  <div class="main-content statement">
    <h3 class="page-heading">Event Statement</h3>

    <StatementHeader />
    <EventStatementTable />
  </div>
</template>

<script>
  import StatementHeader from "@/components/Statements/StatementHeader.vue";
  import EventStatementTable from "@/components/Statements/Event/EventStatementTable.vue";

  export default {
    components: {
      EventStatementTable,
      StatementHeader,
    },
  };
</script>

<style lang="scss">
  .statement {
    &__body {
      .card__title {
        justify-content: flex-start;

        > .slot {
          flex: 1;
          @include flex-center($jc: flex-start);
        }
      }

      .card__content {
        height: calc(100% - 4rem);
      }
    }
  }
</style>
