<template>
  <Card
    class="statement__body"
    :title="selectedDate"
    flexDirection="column"
    :contentGap="0">
    <template v-slot:content>
      <AgGridVue
        style="width: 100%; height: 100%"
        class="ag-theme-alpine data-table__content"
        :columnDefs="columnDefs"
        :rowData="rowData"
        @rowClicked="rowClicked"
        @gridReady="onGridReady">
      </AgGridVue>
    </template>
  </Card>

  <ModalDialog
    v-if="selectedService"
    @close="this.selectedService = null"
    maxWidth="1000">
    <StatementOrdersTable />
  </ModalDialog>
</template>

<script>
  // import store from '@/store';
  import Card from "@/components/Card";
  import { AgGridVue } from "ag-grid-vue3";
  import { useToast } from "vue-toastification";
  import ModalDialog from "@/components/Dialogs/ModalDialog.vue";
  import StatementOrdersTable from "@/components/Statements/Services/StatementOrdersTable.vue";
  import { LocalDate, DateTimeFormatter } from "@js-joda/core";

  export default {
    data() {
      return {
        selectedDate:
          this.$route.query.date ||
          LocalDate.now().format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
        gridApi: null,
        columnApi: null,
        columnDefs: [],
        rowData: [],
        selectedService: null,
      };
    },

    methods: {
      async fetchData() {
        this.loading = true;

        try {
          // API Call: Fetch Service Statement With Order Data

          // const response = await store.state.apiPrivate.client.endpoints.statementsEvents.get(this.eventId);
          // const data = await response?.data?.data;

          // Update state columnDefs  and rowData  with data returned

          // Fake Data
          this.columnDefs = [
            { headerName: "Service Id", field: "serviceId", pinned: "left" },
            { headerName: "Date", field: "date" },
            { headerName: "Total", field: "total" },
            { headerName: "Cancellations", field: "cancellations" },
            { headerName: "Refunds", field: "refunds" },
          ];

          this.rowData = [
            {
              serviceId: "1",
              date: this.selectedDate,
              total: this.formatMoney(0),
              cancellations: this.formatMoney(0),
              refunds: this.formatMoney(0),
            },
          ];
        } catch (error) {
          useToast().error(error);
        }

        this.loading = false;
      },

      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.fetchData();
      },

      rowClicked(data) {
        this.selectedService = data;
      },
    },

    mounted() {
      this.fetchData();
    },

    components: {
      Card,
      AgGridVue,
      ModalDialog,
      StatementOrdersTable,
    },
  };
</script>
